
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class AMaintenanceFormStateMixin extends Vue {
  error: null | { msg: string; code: string } = null;
  get hasError() {
    return this.error !== null;
  }
  set hasError(val: boolean) {
    this.error = val ? { msg: "Unknown", code: "set/by/model" } : null;
  }

  loading: boolean = false;
}
