import Vue from "vue";
import firebase from "firebase/compat/app";
import MaintenanceApp from "./MaintenanceApp.vue";
import vuetify from "@/plugins/vuetify";

export default async function initMaintenanceApp(data: {
  firebaseApp: firebase.app.App;
  invalidBackend: boolean;
  systemDocExists: boolean;
}): Promise<Vue> {
  return new Vue({
    vuetify,
    render: h => h(MaintenanceApp),
  }).$mount("#app");
}
