



























































import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import { systemEndpointUrl } from "../../utils/HttpApi";
import AMaintenanceFormStateMixin from "./AMaintenanceFormStateMixin.vue";

interface InitialUserFormData {
  displayName: string;
  email: string;
  password: string;
  token: string;
}

@Component({ mixins: [AMaintenanceFormStateMixin] })
export default class AInitialUserForm extends Mixins(
  AMaintenanceFormStateMixin
) {
  initialUser: InitialUserFormData = {
    displayName: "",
    email: "",
    password: "",
    token: "",
  };

  async sendUserCreateRequest() {
    try {
      this.loading = true;
      const url = systemEndpointUrl("create-initial-user");
      console.log(url, this.initialUser);

      const reqData: {
        displayName: string;
        email: string;
        password: string | null;
        token?: string | null;
      } = {
        displayName: this.initialUser.displayName.trim(),
        email: this.initialUser.email.trim(),
        password:
          this.initialUser.password.length === 0
            ? null
            : this.initialUser.password,
      };
      if (this.initialUser.token.trim().length > 0) {
        reqData.token = this.initialUser.token.trim();
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
      });
      const responseData = await response.json();

      console.log(responseData);
      if (response.status === 201) {
        this.error = null;
      } else {
        if (responseData.error) {
          this.error = responseData.error;
        } else {
          if (responseData.code === "INVALID/request/auth/token") {
            this.error = {
              code: responseData.code,
              msg: "Check logs for initial user token",
            };
          } else {
            this.error = {
              code: String(response.status),
              msg: response.statusText,
            };
          }
        }
      }
    } catch (err) {
      console.error(err);
      this.error = {
        msg: String(err),
        code: "thrown",
      };
    } finally {
      this.loading = false;
    }
  }
}
