








































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { EXPECTED_DB_VERSION } from "@auditcloud/shared/lib/constants";
import { AppSetupData } from "@/types/initApp";

import AMaintenanceOngoingInfo from "./AMaintenanceOngoingInfo.vue";
import AMaintenanceCompleteInfo from "./AMaintenanceCompleteInfo.vue";
import ASysAdminInitForm from "./ASysAdminInitForm.vue";

@Component({
  components: {
    AMaintenanceOngoingInfo,
    AMaintenanceCompleteInfo,
    ASysAdminInitForm,
  },
})
export default class ASysAdminForms extends Vue {
  @Prop()
  backendStatus!:
    | "system-init"
    | "migration-required"
    | "sysadmin-required"
    | "done";

  @Prop()
  setupData!: AppSetupData;

  reloadApp() {
    window.location.reload();
  }

  get dbVersions() {
    const actual = this.setupData?.backendVersion;
    const expected = EXPECTED_DB_VERSION;
    return actual
      ? {
          actual,
          expected,
        }
      : null;
  }
}
