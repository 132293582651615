







































import axios from "axios";
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";

import { systemEndpointUrl } from "@/utils/HttpApi";
import AMaintenanceFormStateMixin from "./AMaintenanceFormStateMixin.vue";

@Component({ mixins: [AMaintenanceFormStateMixin] })
export default class ASysAdminInitForm extends Mixins(
  AMaintenanceFormStateMixin
) {
  domain = "";

  async triggerSystemInitialization() {
    try {
      this.loading = true;
      const url = systemEndpointUrl("admin", "init-system");

      const res = await axios({
        url,
        method: "POST",
        responseType: "json",
        data: {
          domain: this.domain,
        },
      });
      console.log(res);
    } catch (err) {
      console.error(err);
      this.error = {
        msg: String(err),
        code: "thrown",
      };
    } finally {
      this.loading = false;
    }
  }
}
