





















































import Component from "vue-class-component";
import { Mixins, Prop } from "vue-property-decorator";
import { AppSetupData } from "@/types/initApp";
import AMaintenanceFormStateMixin from "./AMaintenanceFormStateMixin.vue";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";

interface LoginUserFormData {
  email: string;
  password: string;
}

@Component({ mixins: [AMaintenanceFormStateMixin] })
export default class ALoginForm extends Mixins(AMaintenanceFormStateMixin) {
  loginUser: LoginUserFormData = {
    email: "",
    password: "",
  };

  @Prop()
  setupData!: AppSetupData;

  async login() {
    try {
      this.error = null;
      this.loading = true;
      const setupData = this.setupData;
      if (setupData) {
        const auth = getAuth(setupData.firebaseApp);
        await signInWithEmailAndPassword(
          auth,
          this.loginUser.email,
          this.loginUser.password
        );
      }
    } catch (err) {
      console.error(err);
      if (err.code) {
        this.error = {
          msg: String(err),
          code: err.code,
        };
      } else {
        this.error = {
          msg: String(err),
          code: "request/password-reset",
        };
      }
    } finally {
      this.loading = false;
    }
  }

  async resetPassword() {
    try {
      this.loading = true;
      const setupData = this.setupData;
      if (setupData) {
        const auth = getAuth(setupData.firebaseApp);
        await sendPasswordResetEmail(auth, this.loginUser.email);
      }
    } catch (err) {
      console.error(err);
      if (err.code) {
        this.error = {
          msg: String(err),
          code: err.code,
        };
      } else {
        this.error = {
          msg: String(err),
          code: "request/password-reset",
        };
      }
    } finally {
      this.loading = false;
    }
  }
}
