





























import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { AppSetupData } from "./types/initApp";
import {
  CollectionNames,
  DocumentNames,
  FieldPartNames,
} from "@auditcloud/shared/lib/types/common";
import { unlistenerFunction } from "./utils/firestore";
import { EXPECTED_DB_VERSION } from "@auditcloud/shared/lib/constants";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

import ASysAdminForms from "./components/maintenance/ASysAdminForms.vue";
import AInitialUserForm from "./components/maintenance/AInitialUserForm.vue";
import ALoginForm from "./components/maintenance/ALoginForm.vue";
import { updatePwa } from "./utils/pwa";

@Component({
  components: {
    AInitialUserForm,
    ALoginForm,
    ASysAdminForms,
  },
})
export default class MaintenanceApp extends Vue {
  currentUser: { id: string; roles: string[] } | null = null;
  systemDocError: { msg: string; code: string } | null = null;
  setupData: AppSetupData | null = null;

  get backendStatus():
    | "new"
    | "login-required"
    | "system-init"
    | "migration-required"
    | "sys-admin-required"
    | "ready" {
    const setupData = this.setupData;
    if (!setupData || !setupData.systemDocExists) {
      return "new";
    }

    if (this.currentUser === null) {
      return "login-required";
    }

    if (!this.currentUser.roles.includes(SystemRoles.SYSTEM_ADMINISTRATOR)) {
      return "sys-admin-required";
    }

    if (setupData.backendVersion === null) {
      return "system-init";
    }

    if (setupData.backendVersion !== EXPECTED_DB_VERSION) {
      return "migration-required";
    }

    return "ready";
  }

  @Watch("backendStatus")
  logBackendStatus() {
    console.log(
      `MaintenanceApp: status=${this.backendStatus}, setup data:`,
      this.setupData
    );
  }

  setupApp(data: AppSetupData) {
    this.setupData = data;
    this.watchAuthChange();
    this.watchSystemDocument();
  }

  async setCurrentUser(user: null | firebase.default.User) {
    if (user) {
      try {
        const idTokenResult = await user.getIdTokenResult();

        this.currentUser = {
          id: user.uid,
          roles: idTokenResult.claims.roles,
        };
      } catch (err) {
        console.error(err);
        this.currentUser = null;
      }
    } else {
      this.currentUser = null;
    }
    console.log("MaintenanceApp: CurrentUser", this.currentUser);
  }

  systemDocUnlistener: unlistenerFunction | null = null;
  watchSystemDocument() {
    const setupData = this.setupData;

    if (setupData) {
      const db = setupData.firebaseApp.firestore();
      const systemDocRef = db
        .collection(CollectionNames.CONFIGURATION)
        .doc(DocumentNames.CONFIG_SYSTEM);

      if (this.systemDocUnlistener) {
        this.systemDocUnlistener();
        this.systemDocUnlistener = null;
      }
      this.systemDocUnlistener = systemDocRef.onSnapshot({
        next(systemDoc) {
          const backendVersion = (systemDoc.get(
            FieldPartNames.SYSTEM_DB_VERSION
          ) ?? null) as number | null;

          setupData.backendVersion = backendVersion;
          setupData.invalidBackend = EXPECTED_DB_VERSION !== backendVersion;
          setupData.systemDocExists = systemDoc.exists;
        },
        error: error => {
          console.error("MaintenanceApp: Error accessing system doc: ", error);
          this.systemDocError = {
            msg: error.message,
            code: error.code,
          };
        },
      });
    }
  }

  authUnlistener: unlistenerFunction | null = null;
  watchAuthChange() {
    const setupData = this.setupData;
    if (setupData) {
      const auth = setupData.firebaseApp.auth();
      if (this.authUnlistener) {
        this.authUnlistener();
        this.authUnlistener = null;
      }

      this.authUnlistener = auth.onIdTokenChanged(async user => {
        console.log("MaintenanceApp: ID Token Changed", user);
        await this.setCurrentUser(user);
      });
    }
  }

  mounted() {
    this.watchAuthChange();
    this.watchSystemDocument();

    console.log("MaintenanceApp: loaded. Checking for updates.");
    updatePwa("RELOAD", "MaintenanceApp:mounted");
  }

  beforeDestroy() {
    if (this.systemDocUnlistener) {
      this.systemDocUnlistener();
      this.systemDocUnlistener = null;
    }
    if (this.authUnlistener) {
      this.authUnlistener();
      this.authUnlistener = null;
    }
  }
}
